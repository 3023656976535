import WideContent from 'templates/WideContent'

// Master app for admin and team members

export default () =>
  <WideContent>

    <h1 className="h4 my-0">Not Found</h1>

  </WideContent>
