import { classnames as cx, Helmet } from '@mna/react'

export default ({ title, className,  articleWidth, children, centered, left, style  }) =>
  <div className="header-top-none wide-content-template px-3 pb-3" style={style}>
    <div className={cx("lg-width", centered ? "mx-auto" : "mr-auto", className)}>
      <div className={cx(
        "py-2 wide-content-template-inner",
        articleWidth ? 'article-width ml-0 px-0' : ''
      )}>

        { !title ? null :
          <h1 className={cx("mt-1 mb-2", left ? "" : "text-center")}>{ title }</h1>
        }

        { children }

      </div>
    </div>
  </div>
